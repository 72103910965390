<template>
    <validation-observer
        v-slot="{handleSubmit}"
        ref="formValidator"
    >
        <form
            role="form"
            @submit.prevent="handleSubmit(login)"
        >
            <base-input
                ref="inputEmail"
                v-model="email"
                alternative
                class="mb-3"
                vid="email"
                name="Email"
                :rules="{email: true}"
                prepend-icon="ni ni-email-83"
                placeholder="Email"
                :input-data-cy="'inputLoginEmail'"
                :invalid-data-cy="'inputLoginEmailInvalid'"
            />
            <base-input
                v-model="password"
                alternative
                class="mb-3"
                name="Password"
                vid="password"
                prepend-icon="ni ni-lock-circle-open"
                type="password"
                placeholder="Password"
                :input-data-cy="'inputLoginPassword'"
                :invalid-data-cy="'inputLoginPasswordInvalid'"
            />

            <div class="text-center">
                <base-button
                    :loading="isLoading"
                    type="default"
                    native-type="submit"
                    class="my-4"
                    :data-cy="'buttonLoginSubmit'"
                >
                    {{ $t("authentications.login") }}
                </base-button>
            </div>
        </form>
    </validation-observer>
</template>

<script>
import {
    AuthenticationResponseKeys,
    AuthenticationExceptionCodes,
    AuthenticationStoreKeys,
    AuthenticationRequestKeys
} from "@/constants/authenticationConstants";
import {ValidationObserver} from "vee-validate";
import {authenticationService} from "@/services/authenticationService";
import {authenticationClient} from "@/clients/authenticationClient";
import store from "@/main";
import companyMixins from "@/mixins/companyMixins";

export default {
    name: "Login",
    components: {
        ValidationObserver,
    },
    mixins: [companyMixins],
    data() {
        return {
            email: null,
            password: null,
            isLoading: false,
        };
    },
    mounted() {
        this.$refs.inputEmail.$refs.input.focus();
    },
    methods: {
        setCredentialsForAutoLogin() {
            const data = {
                [AuthenticationStoreKeys.KEY_LOGIN_EMAIL]: this.email,
                [AuthenticationStoreKeys.KEY_LOGIN_PASSWORD]: this.password,
            };
            store.dispatch("setCredentialsForAutoLogin", data);
        },
        login() {
            this.isLoading = true;

            authenticationClient
                .login({
                    [AuthenticationRequestKeys.KEY_LOGIN_EMAIL]: this.email,
                    [AuthenticationRequestKeys.KEY_LOGIN_PASSWORD]: this.password,
                    [AuthenticationRequestKeys.KEY_TWO_FACTOR_TOKEN]: authenticationService.getTwoFactorToken(),
                    companyName: this.getCompanyName(),
                    applicationClientName: this.getApplicationClientName()
                })
                .then((result) => {
                    if (result.status === 200) {
                        authenticationService.setToken(result.data[AuthenticationResponseKeys.KEY_TOKEN])

                        const credentialsData = {
                            [AuthenticationStoreKeys.KEY_LOGIN_EMAIL]: this.email,
                        };
                        store.dispatch("setCredentials", credentialsData);

                        this.setCredentialsForAutoLogin();
                        if (result.data[AuthenticationResponseKeys.KEY_TWO_FACTOR_TOKEN_REQUIRED] == false) {
                            this.$router.push(this.$route.query.redirect || "/homepage");
                        } else {
                            const data = {
                                [AuthenticationStoreKeys.KEY_LAST_TREE_CHAR_TELEPHONE_NUMBER]: result.data[AuthenticationResponseKeys.KEY_TELEPHONE_NUMBER],
                            };
                            store.dispatch("moveToTwoFactorStep", data);
                        }
                    }
                }).catch(error => {
                    if (error.response && [400].includes(error.response.status)) {
                        switch (error.response.data.status) {
                        case AuthenticationExceptionCodes.INVALID_CREDENTIALS:
                            authenticationService.resetAuthenticationTokens();
                            this.$refs.formValidator.setErrors({
                                email: [this.$t("authentications.wrongCredentials")],
                                password: [this.$t("authentications.wrongCredentials")]
                            });
                            break;
                        default:
                            store.dispatch("moveToTechnicalAssistanceStep");
                        }
                    }
                }).then(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>
